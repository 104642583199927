import React, { useEffect } from "react";
import Bigcard from "../../SmallComponent/CaseStudiesCard/Bigcard";
import styles from "./CaseStudies.module.css";
import "aos/dist/aos.css";
import IsVisible from "react-is-visible";
import TrackVisibility from "react-on-screen";
import brandImage1 from "../../images/Thumbnails/Satyapal JI.png";
import brandImage2 from "../../images/Thumbnails/Hit and run.png";
import brandImage3 from "../../images/Thumbnails/Corona.png";
import brandImage4 from "../../images/Thumbnails/Brahmastra.png";
import brandImage5 from "../../images/Thumbnails/Unicorn.png";
import brandImage6 from "../../images/Thumbnails/Funky Budha.png";
import brandImage7 from "../../images/Thumbnails/Diablo.png";
import brandImage8 from "../../images/Thumbnails/BINGO.png";
import brandImage9 from "../../images/Thumbnails/OGX.png";
import brandImage10 from "../../images/Thumbnails/Holland America Line.png";
import brandImage11 from "../../images/Thumbnails/Starbucks.png";
import brandImage12 from "../../images/Thumbnails/USHA.png";
import brandImage13 from "../../images/Thumbnails/Dating app.png";
import brandImage14 from "../../images/Thumbnails/Magic Dine.png";
import brandImage15 from "../../images/Thumbnails/Dpod.png";
import brandImage16 from "../../images/Thumbnails/Remotely.png";
import brandImage17 from "../../images/Thumbnails/Starbucks_holiday.png";
import brandImage18 from "../../images/Thumbnails/3D Kitchen Configurator.png";
import brandImage19 from "../../images/Thumbnails/Lipstick _Try-on_.png";
import brandImage20 from "../../images/Thumbnails/Amazon Self-explainer.png";
import brandImage21 from "../../images/Thumbnails/Car Configurator.png";
import brandImage22 from "../../images/Thumbnails/360 img isometric view.png";
import brandImage23 from "../../images/Thumbnails/Astronaut.png";
import brandImage24 from "../../images/Thumbnails/The Collective.png";
import brandImage25 from "../../images/Thumbnails/Modelo Photo Ops.png";
import brandImage26 from "../../images/Thumbnails/Corona Photo Ops.png";
import brandImage27 from "../../images/Thumbnails/Merry Christmas.png";
import brandImage28 from "../../images/Thumbnails/Rakhi Gift.png";
import brandImage29 from "../../images/Thumbnails/WWE Superstar Rey.png";
import brandImage30 from "../../images/Thumbnails/Modelo Football Game.png";
import brandImage31 from "../../images/Thumbnails/CokeStudio.png";
import brandImage32 from "../../images/Thumbnails/Pacifico Photo Ops.png";
import brandImage33 from "../../images/Thumbnails/Khadi India.png";
import brandImage34 from "../../images/Thumbnails/Lego.png";
import brandImage35 from "../../images/Thumbnails/Sunstone.png";
import brandImage36 from "../../images/Thumbnails/Sunrise Pasta Masala.png";
import brandImage37 from "../../images/Thumbnails/MAUI.png";
import brandImage38 from "../../images/Thumbnails/GeoPark.png";
import logo1 from "../../images/brandLogo/L_DS group.png";
import logo2 from "../../images/brandLogo/L_ANMOL.png";
import logo3 from "../../images/brandLogo/L_Corona.png";
import logo4 from "../../images/brandLogo/L_Brahmastra.png";
import logo5 from "../../images/brandLogo/L_one plus.png";
import logo6 from "../../images/brandLogo/L_Funky Buddha.png";
import logo7 from "../../images/brandLogo/L_Diablo.png";
import logo8 from "../../images/brandLogo/L_Bingo.png";
import logo9 from "../../images/brandLogo/L_OGX.png";
import logo10 from "../../images/brandLogo/L_Holland America Line.png";
import logo11 from "../../images/brandLogo/L_Starbucks.png";
import logo12 from "../../images/brandLogo/L_USHA.png";
import logo13 from "../../images/brandLogo/L_Dating app.png";
import logo14 from "../../images/brandLogo/L_MagicDine.png";
import logo15 from "../../images/brandLogo/L_dpod.png";
import logo16 from "../../images/brandLogo/L_Remotely.png";
import logo17 from "../../images/brandLogo/L_Starbucks.png";
import logo18 from "../../images/brandLogo/L_3D Kitchen Configurator​.png";
import logo19 from "../../images/brandLogo/L_Lipstick _Try-on_.png";
import logo20 from "../../images/brandLogo/L_Amazon.png";
import logo21 from "../../images/brandLogo/L_AUDI.png";
import logo22 from "../../images/brandLogo/L_360 img isometric view.png";
import logo23 from "../../images/brandLogo/L_Astronaut.png";
import logo24 from "../../images/brandLogo/L_The Collective.png";
import logo25 from "../../images/brandLogo/L_Modelo Photo Ops.png";
import logo26 from "../../images/brandLogo/L_Corona.png";
import logo27 from "../../images/brandLogo/L_Merry Christmas.png";
import logo28 from "../../images/brandLogo/L_Rakhi Gift.png";
import logo29 from "../../images/brandLogo/L_WWE Superstar Rey.png";
import logo30 from "../../images/brandLogo/L_Modelo Photo Ops.png";
import logo31 from "../../images/brandLogo/L_CokeStudio.png";
import logo32 from "../../images/brandLogo/L_Pacifico Photo Ops.png";
import logo33 from "../../images/brandLogo/L_Khadi India.png";
import logo34 from "../../images/brandLogo/L_Lego.png";
import logo35 from "../../images/brandLogo/L_Sunstone.png";
import logo36 from "../../images/brandLogo/L_Sunrise Pasta Masala.png";
import logo37 from "../../images/brandLogo/L_Maui.png";
import logo38 from "../../images/brandLogo/L_GeoPark.png";

const CaseStudies = (props) => {
  function hideFormbg() {
    let videoRepBG = document.getElementById("cntBG");
    if (videoRepBG) {
      videoRepBG.classList.remove("addFormBgImage");
    }
  }
  useEffect(() => {
    props.setBgNormal();
  }, [props.setBgNormal]);

  return (
    <>
      <div id="caseStudies" className="mb-5 position-relative">
        {/*   <img className={styles.bgimg} src={bg} alt="" /> */}
        <IsVisible>
          {(isVisible) => (
            <div className="text-center text_conatiner mt-5 mb-3 mt-md-0 ">
              <h1
                className={
                  isVisible
                    ? `${styles.pageheading} textAnim`
                    : `${styles.pageheading}`
                }
              >
                PROJECTS
              </h1>
            </div>
          )}
        </IsVisible>
        <div className="container  d-flex justify-content-center align-items-center  p-3 p-md-0">
          <div className={`${styles.row} row mt-5`}>
            {/* <div className="col-6  col-md-4 d-flex justify-content-center align-items-center">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage1} logo={logo1} />
              </a>
            </div> */}
            <div className="col-6 col-md-4 d-flex justify-content-center align-items-center  ">
              {/* <a href="#foooo"> */}
              <a >
                <Bigcard brandImage={brandImage2} logo={logo2} />
              </a>
            </div>
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center  ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage3} logo={logo3} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center  ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage4} logo={logo4} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage5} logo={logo5} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage6} logo={logo6} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage7} logo={logo7} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage8} logo={logo8} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage9} logo={logo9} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage10} logo={logo10} />
              </a>
            </div> */}
            <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> */}
              <a >
                <Bigcard brandImage={brandImage11} logo={logo11} />
              </a>
            </div>
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage12} logo={logo12} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage13} logo={logo13} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage14} logo={logo14} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage15} logo={logo15} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage16} logo={logo16} />
              </a>
            </div> */}
            <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> */}
              <a >
                <Bigcard brandImage={brandImage17} logo={logo17} />
              </a>
            </div>
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage18} logo={logo18} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage19} logo={logo19} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage20} logo={logo20} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage21} logo={logo21} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage22} logo={logo22} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage23} logo={logo23} />
              </a>
            </div> */}
            <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> */}
              <a >
                <Bigcard brandImage={brandImage24} logo={logo24} />
              </a>
            </div>
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage25} logo={logo25} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage26} logo={logo26} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage27} logo={logo27} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage28} logo={logo28} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage29} logo={logo29} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage30} logo={logo30} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage31} logo={logo31} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage32} logo={logo32} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage33} logo={logo33} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage34} logo={logo34} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage35} logo={logo35} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage36} logo={logo36} />
              </a>
            </div> */}
            {/* <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> *}
              <a >
                <Bigcard brandImage={brandImage37} logo={logo37} />
              </a>
            </div> */}
            <div className="col-6 col-md-4 d-flex justify-content-center align-items-center   ">
              {/* <a href="#foooo"> */}
              <a >
                <IsVisible>
                  {(isVisible) => (
                    <Bigcard
                      brandImage={brandImage38}
                      logo={logo38}
                      hideBG={isVisible ? hideFormbg : ""}
                    />
                  )}
                </IsVisible>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudies;
