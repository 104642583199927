import React, { useEffect } from "react";
import styles from "./Bodytext.module.css";
import IsVisible from "react-is-visible";

const Bodytext = (props) => {
  useEffect(() => {
    props.setBgAnim();
  }, [props.setBgAnim]);

  return (
    <>
      <IsVisible>
        {(isVisible) => (
          <div ref={props.reg} id="about" className={styles.mainText}>
            <div className={styles.Textwrapper}>
              {/* <h1 className={isVisible ? "textAnim" : ""}>
                Over the years we’ve achieved world-class creative production on
                a global scale. We’re positively provoking growth for Fortune
                500 and Global 2000 companies across key industries.
              </h1> */}
              <h1 className={isVisible ? "textAnim" : ""}>
                  Trifino Innovations is an Indian innovation startup
                  based in New Delhi, specializing in Strategy &
                  Consulting, Technology, 3D, and Design. We are
                  dedicated to helping clients unlock the full potential
                  of their innovation investments and achieve their
                  organizational objectives through our extensive
                  domain expertise.
              </h1>
            </div>
            <div className={styles.Textwrapper2}>
              <h1 className={isVisible ? "textAnim" : ""}>
                  Trifino combines originality, creativity, and technical skill to
                  tackle clients' most challenging problems with cutting-edge solutions.
                  We pride ourselves on our ability to deliver unmatched innovation
                  services that drive significant transformation. Our guiding principle, 
                  "Loyalty to Commitment," reflects our dedication to upholding 
                  values that contribute to the Indian economy. By creating job opportunities 
                  and driving impactful innovations, we aim to make a meaningful difference 
                  through our work.
              </h1>
            </div>
            {/* <div className={styles.Textwrapper3}>
              <h1 className={isVisible ? "textAnim" : ""}>
                  Our guiding principle, "Loyalty to Commitment," reflects our dedication to upholding 
                  values that contribute to the Indian economy. By creating job opportunities 
                  and driving impactful innovations, we aim to make a meaningful difference 
                  through our work.
              </h1>
            </div> */}
          </div>
        )}
      </IsVisible>
    </>
  );
};

export default Bodytext;
