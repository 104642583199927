import React, { useRef, useEffect, useState } from "react";
import bandlogo from "../../images/nav_logo.png";
import styles from "./Navbar.module.css";
import Hamburger from "hamburger-react";
import instalogo from "../../images/navBarSocialLogo/instagram.png";
import facebooklogo from "../../images/navBarSocialLogo/facebook.png";
import linkedinogo from "../../images/navBarSocialLogo/linkedin-in.png";
import twitterlogo from "../../images/navBarSocialLogo/twitter.png";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  const href = window.location.href;
  const [isFixed, setIsFixed] = useState(false);

  function careerClick() {
    console.log(window.location.href.split("/").pop());
    let careerPage = document.getElementById("careerPage");
    careerPage.style.display = "block";
    let mainPage = document.getElementById("main_component");
    mainPage.style.display = "none";
    let videomain = document.getElementById("cmpVid");
    videomain.pause();
    //videomain.removeAttribute("src");
    videomain.removeAttribute("autoplay");
    setTimeout(() => {
      videomain.setAttribute("muted", true);
    }, 1000);
  }
  function careerClickMobile() {
    setOpen(false);
    let careerPage = document.getElementById("careerPage");
    careerPage.style.display = "block";
    let mainPage = document.getElementById("main_component");
    mainPage.style.display = "none";

    let videomain = document.getElementById("cmpVid");
    videomain.pause();
    videomain.setAttribute("muted", true);
    videomain.removeAttribute("playsInline");
    videomain.removeAttribute("autoplay");
    videomain.style.display = "none";
  }

  useEffect(() => {
    let bdyScroll = document.body.style.overflow;
    console.log(bdyScroll);
    if (bdyScroll == "hidden") {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
    function handleScroll() {
      if (window.scrollY > window.innerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("keydown", handleScroll);
    };
  }, [isOpen]);
  return (
    <>
      <nav
        className={` ${isOpen ? styles.nanMainActive : styles.navMain}   ${
          !isFixed ? styles.smoothhide : styles.smoothshow
        } navbar navbar-expand-lg fixed-top `}
      >
        <div className="container-fluid mx-auto container-lg">
          <a className={`${styles.bandLogo} navbar-brand d-lg-none`} href="/">
            <img src={bandlogo} alt="VRARMR" />
          </a>
          <Hamburger
            className={`${styles.hemburgerbtn}  navbar-toggler`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded={isOpen ? true : false}
            aria-label="Toggle navigation"
            onClick={() => setOpen(!isOpen)}
            toggled={isOpen}
            toggle={setOpen}
          />

          <ul className={`navbar ${styles.desktopView} ${styles.nav_item}`}>
            <a
              className={`${styles.bandLogo} navbar-brand d-sm-none d-lg-flex`}
              href="/"
            >
              <img src={bandlogo} alt="bandlogo" />
            </a>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" onClick={() => window.location.replace("#about")}> {/* href="#about" */}
                ABOUT
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" aria-current="page" onClick={() => window.location.replace("#about")}> {/* href="#about" *}
                CLIENTS
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" onClick={() => window.location.replace("#services")}> {/*href="#services"*/}
                OFFERINGS
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={() => window.location.replace("#caseStudies")} > {/*href="#caseStudies"*/}
                PROJECTS
              </a>
            </li>
            <li className="nav-item ">
              <a className="nav-link" onClick={() => window.location.replace("#packages")} > {/*href="#packages"*/}
                COLLABORATE
              </a>
            </li>
            {/* <li className="nav-item" style={{ cursor: "pointer" }}>
              <a className="nav-link" onClick={careerClick}>    {/*href="#career" *}
                CAREER
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" onClick={() => window.location.replace("#contact")} >{/*href="#contact"*}
                CONTACT
              </a>
            </li> */}
          </ul>
          <div
            className={`${isOpen ? "" : "collapse"} ${
              styles.mobileView
            } navbar-collapse`}
            id="navbarText"
          >
            <ul
              className={`${styles.list} ${styles.mobileView} navbar-nav me-auto mb-2 mb-lg-0 `}
            >
              <div className={styles.navItem}>
                <li className="nav-item">
                  <a
                    // href="#about"
                    // onClick={() => setOpen(false)}
                    onClick={() => {
                      window.location.replace("#about");
                      setOpen(false);
                    }}
                    aria-current="page"
                    className={
                      href.split("#")[1] === "about"
                        ? "aboutSec nav-link"
                        : "nav-link"
                    }
                  >
                    ABOUT
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    // href="#about"
                    // onClick={() => setOpen(false)}
                    onClick={() => {
                      window.location.replace("#about");
                      setOpen(false);
                    }}
                    aria-current="page"
                    className={
                      href.split("#")[1] === "about"
                        ? "aboutSec nav-link"
                        : "nav-link"
                    }
                  >
                    CLIENTS
                  </a>
                </li> */}

                <li className="nav-item">
                  <a
                    // onClick={() => setOpen(false)}
                    // href="#services"
                    onClick={() => {
                      setOpen(false);
                      window.location.replace("#services");
                    }}
                    className={
                      href.split("#")[1] === "services"
                        ? "aboutSec nav-link"
                        : "nav-link"
                    }
                  >
                    OFFERINGS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    // onClick={() => setOpen(false)}
                    // href="#caseStudies"
                    onClick={() => {
                      setOpen(false);
                      window.location.replace("#caseStudies");
                    }}
                    className={
                      href.split("#")[1] === "caseStudies"
                        ? "aboutSec nav-link"
                        : "nav-link"
                    }
                  >
                    PROJECTS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    // onClick={() => setOpen(false)}
                    // href="#packages"
                    onClick={() => {
                      setOpen(false);
                      window.location.replace("#packages");
                    }}
                    className={
                      href.split("#")[1] === "packages"
                        ? "aboutSec nav-link"
                        : "nav-link"
                    }
                  >
                    COLLABORATE
                  </a>
                </li>
                {/* <li className="nav-item" style={{ cursor: "pointer" }}>
                  <a
                    onClick={careerClickMobile}
                    href="#career"
                    className={
                      href.split("#")[1] === "career"
                        ? "aboutSec nav-link"
                        : "nav-link"
                    }
                  >
                    CAREER
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <a
                    // onClick={() => setOpen(false)}
                    // href="#contact"
                    onClick={() => {
                      window.location.replace("#contact");
                      setOpen(false);
                    }}                    
                    className={
                      href.split("#")[1] === "contact"
                        ? "aboutSec nav-link"
                        : "nav-link"
                    }
                  >
                    CONTACT
                  </a>
                </li> */}
              </div>
              <div className={styles.socialIcon}>
                <a href="https://www.instagram.com/vrarmrofficial/">
                  <img
                    src={instalogo}
                    alt="ARVRMR_instagram..."
                    className={styles.icon}
                  />
                </a>
                <a href="https://www.facebook.com/vrarmr/">
                  <img
                    src={facebooklogo}
                    alt="ARVRMR_facebook..."
                    className={styles.icon}
                  />
                </a>
                <a href="https://www.linkedin.com/company/vrarmr/mycompany/">
                  <img
                    src={linkedinogo}
                    alt="ARVRMR_linkedin..."
                    className={styles.icon}
                  />
                </a>
                <a href="https://twitter.com/vrarmrofficial">
                  <img
                    src={twitterlogo}
                    alt="ARVRMR_twitter..."
                    className={styles.icon}
                  />
                </a>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
